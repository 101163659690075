import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import Cookies from "js-cookie";
import { BrowserRouter } from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

axios.interceptors.request.use((request) => {
	if (request.url) {
		request.url = process.env.REACT_APP_API_BACKEND_SERVER + request.url;
	}
	if (Cookies.get('tokenAccess')) {
		request.headers['Authorization'] = `Bearer ${Cookies.get('tokenAccess')}`;
	}
	return request;
})

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
