import { Route, Routes, Navigate } from 'react-router-dom';
import axios from "axios";

// Private Page
import Class from './pages/class';
import FourZeroFour from './pages/error/404';

const App = () => {
    // Response interceptor for API calls
    axios.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        if (error?.response?.data?.message === "Unauthenticated.") {
            window.location.href = process.env.REACT_APP_MAIN_DOMAIN
        }
        return Promise.reject(error);
    });

    return <>
        <Routes>
            {/* Class Page Route */}
            <Route path="/class/:slug/:encryptedToken" element={<Class />} />
            {/* 404 Page Route */}
            <Route path="/404" element={<FourZeroFour />} />
            {/* Error Page Route */}
            <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
    </>
}

export default App;
